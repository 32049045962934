import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { IconClass } from '@szegedsw/icon';
import { ElemList } from '@szegedsw/select';
import { addIcon, convertToBool } from '@szegedsw/table';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimepickerComponent } from 'ngx-bootstrap/timepicker';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { api } from '../../config/config';
import { Qso } from '../../qso-typer/qso-typer.component';
import { translate } from '../../services/translate';

@Component({
   selector: 'app-qso-form',
   templateUrl: './qso-form.component.html',
   styleUrls: ['./qso-form.component.css'],
})
export class QsoFormComponent implements OnInit, OnDestroy {
   @Input('fieldsToShow') fieldsToShow;
   @Input('qsoForm') qsoForm: FormGroup;
   @Output('qsoForm') qsoFormChange = new EventEmitter<FormGroup>();
   @Input('qsoEditForm') qsoEditForm: FormGroup;
   @Output('qsoEditForm') qsoEditFormChange = new EventEmitter<FormGroup>();
   @Input('autoTime') autoTime: boolean;
   @Input('isDupe') isDupe: boolean;
   @Input('isFreq') isFreq: boolean;
   @Input('autoNum') autoNum: boolean;
   @Input('copyNumber') copyNumber: boolean;
   @Input('copyExchange') copyExchange: boolean;
   @Input('isOnlineChosenExchange') isOnlineChosenExchange: boolean;
   @Input('isOffline') isOffline: boolean;
   @Output('isOffline') isOfflineChange = new EventEmitter<boolean>();
   @Input('editQso') editQso: boolean;
   @Output('editQso') editQsoChange = new EventEmitter<boolean>();
   @Input('renderQso') renderQso: boolean;
   @Output('renderQso') renderQsoChange = new EventEmitter<boolean>();
   @Input('renderTable') renderTable: boolean;
   @Output('renderTableChange') renderTableChange = new EventEmitter<boolean>();
   @Input('mode') mode: any[];
   @Input('band') band: any[];
   @Input('eventId') eventId: string;
   @Input('logId') logId: string;
   @Input('setMode') setMode: string;
   @Input('periodMins') periodMins: number;
   @Input('qsos') qsos: Qso[];
   @Output('qsos') qsosChange = new EventEmitter<Qso[]>();
   @Input('offlineQsos') offlineQsos: Qso[];
   @Output('offlineQsos') offlineQsosChange = new EventEmitter<Qso[]>();
   @Input('origBand') origBand: string;
   @Input('origMode') origMode: string;
   @Input('loadBandEvent') loadBandEventObservable: Observable<void>;
   @Input('loadModeEvent') loadModeEventObservable: Observable<void>;
   @Input('resetEvent') resetEventObservable: Observable<void>;
   @Output('loadEvents') loadEvents = new EventEmitter();
   @Input('contestName') contestName?: string;
   @Input('ownCallsign') ownCallsign: string;
   @Output('onChangeForm') changeForm = new EventEmitter<Qso[]>();
   @Output('resetQsoForm') qsoFormReset = new EventEmitter<Qso[]>();
   @Output('rstSet') rstSet = new EventEmitter<Qso[]>();
   @Output('onCallsignChange') callsignChange = new EventEmitter<Qso[]>();
   @Output('onUpdateEditQso') updateEditQso = new EventEmitter<Qso[]>();

   bsConfig: Partial<BsDatepickerConfig> = {
      containerClass: environment.production ? 'theme-blue' : 'theme-red',
      isAnimated: true,
      adaptivePosition: true,
      dateInputFormat: 'YYYY-MM-DD',
   };

   @ViewChild('first', { static: false }) firstElement: ElementRef;
   @ViewChild('call', { static: false }) callElement: ElementRef;
   @ViewChild('num', { static: false }) numElement: ElementRef;
   @ViewChild('exch', { static: false }) exchElement: ElementRef;
   @ViewChild('wwl', { static: false }) wwlElement: ElementRef;
   @ViewChild('time', { static: false }) timeElement: TimepickerComponent;

   logService: HttpService;

   elemList: ElemList = [];

   private loadBandEventSubscription: Subscription;
   private loadModeEventSubscription: Subscription;
   private resetEventSubscription: Subscription;

   loadBandEvent: Subject<string> = new Subject<string>();
   loadModeEvent: Subject<string> = new Subject<string>();
   resetEvent: Subject<string> = new Subject<string>();
   loadBandObs = this.loadBandEvent.asObservable();
   loadModeObs = this.loadModeEvent.asObservable();
   resetObs = this.resetEvent.asObservable();

   regexSsb = '[1-5][1-9]$';
   regexCw = '[1-5][1-9][1-9]$';
   regexWwl = '(?:[A-Za-z]{2}\\d{2})*(?:[A-Za-z]{2}|\\d{2})?';

   constructor(private el: ElementRef, private http: HttpClient) {
      this.logService = new HttpService(this.http, api('nest', 'qso/online-logger'));
   }

   ngOnInit() {
      this.loadBandEventSubscription = this.loadBandEventObservable.subscribe(() => this.loadBandEvent.next());
      this.loadModeEventSubscription = this.loadModeEventObservable.subscribe(() => this.loadModeEvent.next());
      this.resetEventSubscription = this.resetEventObservable.subscribe(() => this.resetEvent.next());
   }

   ngOnDestroy() {
      this.loadBandEventSubscription.unsubscribe();
      this.loadModeEventSubscription.unsubscribe();
      this.resetEventSubscription.unsubscribe();
   }

   toDate(date: Date): string {
      return formatDate(date, 'yyyy-MM-dd', 'hu');
   }

   toTime(date: Date): string {
      return formatDate(date, 'HH:mm', 'hu');
   }

   onNext(field: string) {
      if (field === 'call') {
         if (this.fieldsToShow.sNum) {
            this.numElement.nativeElement.focus();
         } else if (this.fieldsToShow.sExch) {
            this.exchElement.nativeElement.focus();
            setTimeout(() => {
               this.qsoForm
                  .get('rExch')
                  .setValue(this.qsoForm.get('rExch').value !== null ? (this.qsoForm.get('rExch').value as string).trim() : '');
            }, 50);
         } else if (this.fieldsToShow.sWwl) {
            this.wwlElement.nativeElement.focus();
            setTimeout(() => {
               this.qsoForm
                  .get('sWwl')
                  .setValue(this.qsoForm.get('sWwl').value !== null ? (this.qsoForm.get('sWwl').value as string).trim() : '');
            }, 50);
         }
      } else if (field === 'num') {
         if (this.fieldsToShow.sExch) {
            this.exchElement.nativeElement.focus();
            setTimeout(() => {
               this.qsoForm
                  .get('rExch')
                  .setValue(this.qsoForm.get('rExch').value !== null ? (this.qsoForm.get('rExch').value as string).trim() : '');
            }, 50);
         } else if (this.fieldsToShow.sWwl) {
            this.wwlElement.nativeElement.focus();
            setTimeout(() => {
               this.qsoForm
                  .get('sWwl')
                  .setValue(this.qsoForm.get('sWwl').value !== null ? (this.qsoForm.get('sWwl').value as string).trim() : '');
            }, 50);
         } else if (this.autoTime) {
            this.callElement.nativeElement.focus();
         } else {
            (document.getElementsByClassName('bs-timepicker-field')[1] as any).focus();
         }
      } else if (field === 'exch') {
         if (this.fieldsToShow.sWwl) {
            this.wwlElement.nativeElement.focus();
            setTimeout(() => {
               this.qsoForm
                  .get('sWwl')
                  .setValue(this.qsoForm.get('sWwl').value !== null ? (this.qsoForm.get('sWwl').value as string).trim() : '');
            }, 50);
         } else if (this.autoTime) {
            this.callElement.nativeElement.focus();
         } else {
            (document.getElementsByClassName('bs-timepicker-field')[1] as any).focus();
         }
      } else if (field === 'wwl') {
         if (this.autoTime) {
            this.callElement.nativeElement.focus();
         } else {
            (document.getElementsByClassName('bs-timepicker-field')[1] as any).focus();
         }
      } else if (field === 'time') {
         this.callElement.nativeElement.focus();
      }
      if (this.qsoForm.get('call').value) {
         setTimeout(() => {
            this.qsoForm
               .get('call')
               .setValue(this.qsoForm.get('call').value !== null ? (this.qsoForm.get('call').value as string).trim() : '');
         }, 50);
      }
      if (this.qsoEditForm.get('call').value) {
         setTimeout(() => {
            this.qsoEditForm
               .get('call')
               .setValue(
                  this.qsoEditForm.get('call').value !== null ? (this.qsoEditForm.get('call').value as string).trim() : '',
               );
         }, 50);
      }
      setTimeout(() => {
         this.qsoEditForm
            .get('call')
            .setValue(
               this.qsoEditForm.get('call').value !== null
                  ? (this.qsoEditForm.get('call').value as string).trim().replace(' ', '')
                  : '',
            );
         this.qsoForm
            .get('rExch')
            .setValue(
               this.qsoForm.get('rExch').value !== null
                  ? (this.qsoForm.get('rExch').value as string).trim().replace(' ', '')
                  : '',
            );
         this.qsoForm
            .get('sWwl')
            .setValue(
               this.qsoForm.get('sWwl').value !== null ? (this.qsoForm.get('sWwl').value as string).trim().replace(' ', '') : '',
            );
      }, 100);
      this.onChangeValues();
   }

   onChangeFreq() {
      const form = this.editQso ? this.qsoEditForm : this.qsoForm;
      const freq = form.get('freq').value;
      if (freq >= 1800 && freq <= 2000 && this.band.findIndex((b) => b.name === '160M') > -1) {
         form.get('band').setValue('160M');
         form.get('freq').setErrors({ incorrect: false });
         form.get('freq').updateValueAndValidity();
      } else if (freq >= 3500 && freq <= 4000 && this.band.findIndex((b) => b.name === '80M') > -1) {
         form.get('band').setValue('80M');
         form.get('freq').setErrors({ incorrect: false });
         form.get('freq').updateValueAndValidity();
      } else if (freq >= 7000 && freq <= 7300 && this.band.findIndex((b) => b.name === '40M') > -1) {
         form.get('band').setValue('40M');
         form.get('freq').setErrors({ incorrect: false });
         form.get('freq').updateValueAndValidity();
      } else if (freq >= 14000 && freq <= 14350 && this.band.findIndex((b) => b.name === '20M') > -1) {
         form.get('band').setValue('20M');
         form.get('freq').setErrors({ incorrect: false });
         form.get('freq').updateValueAndValidity();
      } else if (freq >= 21000 && freq <= 21450 && this.band.findIndex((b) => b.name === '15M') > -1) {
         form.get('band').setValue('15M');
         form.get('freq').setErrors({ incorrect: false });
         form.get('freq').updateValueAndValidity();
      } else if (freq >= 28000 && freq <= 29700 && this.band.findIndex((b) => b.name === '10M') > -1) {
         form.get('band').setValue('10M');
         form.get('freq').setErrors({ incorrect: false });
         form.get('freq').updateValueAndValidity();
      } else {
         form.get('freq').setErrors({ incorrect: true });
      }
      this.onChangeValues();
   }

   onSend() {
      const form = this.editQso ? this.qsoEditForm : this.qsoForm;
      form.get('sNum').enable();
      form.get('sExch').enable();
      form.markAllAsTouched();
      form.updateValueAndValidity();
      if (form.valid) {
         let data: Qso = undefined;
         if (this.editQso) {
            data = this.qsoEditForm.getRawValue();
            Object.assign(data, { callsign: data.call });
            const offset = new Date().getTimezoneOffset() * 60000;
            let dateTime = new Date(
               new Date(data.date).getFullYear(),
               new Date(data.date).getMonth(),
               new Date(data.date).getDate(),
               new Date(data.time).getHours(),
               new Date(data.time).getMinutes(),
               new Date(data.time).getSeconds(),
            );
            dateTime = new Date(new Date().setTime(new Date(dateTime).getTime() - offset));
            Object.assign(data, { dateTime: dateTime.toISOString() });
            data.rRst = data.rRst.toString();
            data.sRst = data.sRst.toString();
            this.logService.patch<Qso>('', { ...data, eventId: this.eventId, logId: this.logId, qsoId: data._id }, (res) => {
               if (isResultValid(res)) {
                  data._id = res.body._id;
                  data.status = res.body.status;
                  data.dxcc = res.body.dxcc;
                  data.callsign = res.body.callsign;
                  data.multis = res.body.multis;
                  data.validation = res.body.validation;
                  data.xQso = res.body.status === 'X_QSO';
                  data.dupe = this.isDupe;
                  data.date = this.toDate(new Date(data.date));
                  data.time = this.toTime(new Date(data.time));
                  const h = parseInt(data.time.split(':')[0]);
                  const m = parseInt(data.time.split(':')[1]);
                  if (!this.isFreq) {
                     data.freq = undefined;
                  }
                  if (this.periodMins < 60) {
                     data.period = h * 100 + Math.floor(m / this.periodMins) * this.periodMins;
                  } else if (this.periodMins === 60) {
                     data.period = h * 100;
                  }
                  const i = this.qsos.findIndex((q) => q.nr === data.nr);
                  this.qsos[i] = data;
                  addIcon(this.qsos, 'edit', new IconClass('fas:faEdit', 'edit', [], translate('Modify QSO')));
                  addIcon(this.qsos, 'delete', new IconClass('fas:faTimes', 'delete', [], translate('Delete QSO')));
                  this.editQso = false;
                  localStorage.setItem('editQso', 'false');
                  this.updateEditQso.emit();
                  this.renderQso = false;
                  setTimeout(() => {
                     this.renderQso = true;
                     setTimeout(() => {
                        this.loadEvents.emit();
                        setTimeout(() => {
                           this.onChangeForm();
                           this.firstElement.nativeElement.focus();
                           setTimeout(() => {
                              if (this.autoTime) {
                                 this.callElement.nativeElement.focus();
                              } else {
                                 this.firstElement.nativeElement.focus();
                              }
                              this.renderTable = true;
                              this.renderTableChange.emit(this.renderTable);
                              this.qsoForm.get('band').setValue(localStorage.getItem('origBand'));
                              this.qsoForm.get('mode').setValue(localStorage.getItem('origMode'));
                              if (this.copyExchange && localStorage.getItem('origExch') != null) {
                                 this.qsoForm.get('sExch').setValue(localStorage.getItem('origExch'));
                              }
                              if (this.copyNumber && localStorage.getItem('origNum') != null) {
                                 this.qsoForm.get('sNum').setValue(localStorage.getItem('origNum'));
                              }
                              this.onChangeBand();
                              this.setRST();
                           }, 100);
                        }, 100);
                     }, 100);
                  }, 200);
               }
            });
         } else {
            data = this.qsoForm.getRawValue();
            Object.assign(data, { callsign: data.call });
            const offset = new Date().getTimezoneOffset() * 60000;
            let dateTime = new Date(
               new Date(data.date).getFullYear(),
               new Date(data.date).getMonth(),
               new Date(data.date).getDate(),
               new Date(data.time).getHours(),
               new Date(data.time).getMinutes(),
               new Date(data.time).getSeconds(),
            );
            dateTime = new Date(new Date().setTime(new Date(dateTime).getTime() - offset));
            Object.assign(data, { dateTime: dateTime.toISOString() });
            data.rRst = data.rRst.toString();
            data.sRst = data.sRst.toString();
            data = { ...data, nr: this.qsos[0] ? this.qsos[0].nr + 1 : 1 };
            data.dupe = this.isDupe;
            if (!this.isFreq) {
               data.freq = undefined;
            }
            this.logService.post<Qso>({ eventId: this.eventId, logId: this.logId, qsos: [{ ...data }] }, (res) => {
               if (isResultValid(res)) {
                  const qso: Qso = res.body[0];
                  data._id = qso._id;
                  data.status = qso.status;
                  data.dxcc = qso.dxcc;
                  data.callsign = qso.callsign;
                  data.multis = qso.multis;
                  data.validation = qso.validation;
                  data.xQso = false;
                  data.date = this.toDate(new Date(data.date));
                  data.time = this.toTime(new Date(data.time));
                  const h = parseInt(data.time.split(':')[0]);
                  const m = parseInt(data.time.split(':')[1]);
                  if (this.periodMins < 60) {
                     data.period = h * 100 + Math.floor(m / this.periodMins) * this.periodMins;
                  } else if (this.periodMins === 60) {
                     data.period = h * 100;
                  }
                  this.qsos.unshift(data);
                  addIcon(this.qsos, 'edit', new IconClass('fas:faEdit', 'edit', [], translate('Modify QSO')));
                  addIcon(this.qsos, 'delete', new IconClass('fas:faTimes', 'delete', [], translate('Delete QSO')));
                  convertToBool(this.qsos, ['dupe', 'xQso']);
                  this.renderTable = false;
                  this.renderTableChange.emit(this.renderTable);
                  if (this.autoNum) {
                     if (!this.fieldsToShow.sNum && this.isOnlineChosenExchange) {
                        if (!isNaN(Number(this.qsoForm.get('sExch').value))) {
                           this.qsoForm.get('sExch').setValue(String(Number(this.qsoForm.get('sExch').value) + 1));
                        }
                     } else {
                        this.qsoForm.get('sNum').setValue(this.qsoForm.get('sNum').value + 1);
                     }
                  } else if (!this.copyNumber) {
                     this.qsoForm.get('sNum').reset();
                  }
                  if (!this.copyExchange) {
                     if (!this.isOnlineChosenExchange || !this.autoNum) {
                        this.qsoForm.get('sExch').reset();
                     }
                  }
                  this.qsoForm.get('rNum').reset();
                  this.qsoForm.get('rExch').reset();
                  this.qsoForm.get('call').reset();
                  this.onChangeForm();
                  this.setRST();
                  this.firstElement.nativeElement.focus();
                  setTimeout(() => {
                     if (this.autoTime) {
                        this.callElement.nativeElement.focus();
                     } else {
                        (document.getElementsByClassName('bs-timepicker-field')[1] as any).value = null;
                        (document.getElementsByClassName('bs-timepicker-field')[1] as any).focus();
                     }
                     this.renderTable = true;
                     this.renderTableChange.emit(this.renderTable);
                  }, 10);
               } else {
                  data.offline = true;
                  this.isOffline = true;
                  data.xQso = false;
                  data.date = this.toDate(new Date(data.date));
                  data.time = this.toTime(new Date(data.time));
                  const h = parseInt(data.time.split(':')[0]);
                  const m = parseInt(data.time.split(':')[1]);
                  if (this.periodMins < 60) {
                     data.period = h * 100 + Math.floor(m / this.periodMins) * this.periodMins;
                  } else if (this.periodMins === 60) {
                     data.period = h * 100;
                  }
                  this.offlineQsos.push(data);
                  this.qsos.unshift(data);
                  addIcon(this.qsos, 'edit', new IconClass('fas:faEdit', 'edit', [], translate('Modify QSO')));
                  addIcon(this.qsos, 'delete', new IconClass('fas:faTimes', 'delete', [], translate('Delete QSO')));
                  convertToBool(this.qsos, ['dupe', 'xQso']);
                  this.renderTable = false;
                  this.renderTableChange.emit(this.renderTable);
                  if (this.autoNum) {
                     if (!this.fieldsToShow.sNum && this.isOnlineChosenExchange) {
                        if (!isNaN(Number(this.qsoForm.get('sExch').value))) {
                           this.qsoForm.get('sExch').setValue(String(Number(this.qsoForm.get('sExch').value) + 1));
                        }
                     } else {
                        this.qsoForm.get('sNum').setValue(this.qsoForm.get('sNum').value + 1);
                     }
                  } else if (!this.copyNumber) {
                     this.qsoForm.get('sNum').reset();
                  }
                  if (!this.copyExchange) {
                     if (!this.isOnlineChosenExchange || !this.autoNum) {
                        this.qsoForm.get('sExch').reset();
                     }
                  }
                  this.qsoForm.get('rNum').reset();
                  this.qsoForm.get('rExch').reset();
                  this.qsoForm.get('rWwl').reset();
                  this.qsoForm.get('call').reset();
                  this.onChangeForm();
                  this.setRST();
                  this.firstElement.nativeElement.focus();
                  setTimeout(() => {
                     if (this.autoTime) {
                        this.callElement.nativeElement.focus();
                     } else {
                        (document.getElementsByClassName('bs-timepicker-field')[1] as any).value = null;
                        (document.getElementsByClassName('bs-timepicker-field')[1] as any).focus();
                     }
                     this.renderTable = true;
                     this.renderTableChange.emit(this.renderTable);
                  }, 10);
               }
            });
         }
      } else {
         for (const key of Object.keys(form.controls)) {
            if (form.controls[key].invalid) {
               const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
               invalidControl.focus();
               break;
            }
         }
      }
      this.onChangeValues();
      form.get('sNum').disable();
      form.get('sExch').disable();
   }

   onChangeBand() {
      const form = this.editQso ? this.qsoEditForm : this.qsoForm;
      if (form.get('band')) {
         const band = form.get('band').value;
         if (band === '160M') {
            form.get('freq').setValue(1800);
         }
         if (band === '80M') {
            form.get('freq').setValue(3500);
         }
         if (band === '40M') {
            form.get('freq').setValue(7000);
         }
         if (band === '20M') {
            form.get('freq').setValue(14000);
         }
         if (band === '15M') {
            form.get('freq').setValue(21000);
         }
         if (band === '10M') {
            form.get('freq').setValue(28000);
         }
      }
      this.onChangeValues();

      if (!this.editQso) {
         localStorage.setItem('origBand', this.qsoForm.get('band').value);
      }
   }

   onSaveData() {
      if (!this.editQso) {
         if (this.copyExchange) {
            localStorage.setItem('origExch', this.qsoForm.get('sExch').value);
         }
         if (this.copyNumber) {
            localStorage.setItem('origNum', this.qsoForm.get('sNum').value);
         }
      }
   }

   jumpToDate() {
      this.firstElement.nativeElement.focus();
   }

   onChangeForm() {
      this.changeForm.emit();
   }

   resetQsoForm() {
      this.qsoFormReset.emit();
   }

   setRST() {
      const form = this.editQso ? this.qsoEditForm : this.qsoForm;
      if (form.get('mode') && form.get('mode') !== null) {
         if (form.get('mode').value === 'CW' || form.get('mode').value === 'RY' || form.get('mode').value === 'DG') {
            form.get('sRst').setValue('599');
            form.get('rRst').setValue('599');
            this.setMode = 'cw';
         } else {
            form.get('sRst').setValue('59');
            form.get('rRst').setValue('59');
            this.setMode = 'ssb';
         }
      }
      if (!this.editQso) {
         localStorage.setItem('origMode', this.qsoForm.get('mode').value);
      }
      this.onChangeForm();
   }

   onCallsignChange() {
      this.callsignChange.emit();
   }

   onChangeValues() {
      this.qsosChange.emit(this.qsos);
      this.qsoFormChange.emit(this.qsoForm);
      this.qsoEditFormChange.emit(this.qsoEditForm);
      this.editQsoChange.emit(this.editQso);
      this.renderQsoChange.emit(this.renderQso);
      this.renderTableChange.emit(this.renderTable);
      this.offlineQsosChange.emit(this.offlineQsos);
      this.isOfflineChange.emit(this.isOffline);
   }

   onReset() {
      if (this.editQso) {
         this.qsoEditForm.reset();
         this.editQso = false;
         localStorage.setItem('editQso', 'false');
      } else {
         this.qsoForm.get('call').setValue('');
         this.qsoForm.get('rNum').setValue('');
         this.qsoForm.get('rExch').setValue('');
         this.qsoForm.get('rWwl').setValue('');
      }
      this.onChangeValues();
      localStorage.setItem('qsoFormValues', JSON.stringify(this.qsoForm.getRawValue()));
      localStorage.setItem('qsoEditFormValues', JSON.stringify(this.qsoEditForm.getRawValue()));
   }
}
