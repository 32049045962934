import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from '@szegedsw/http.service';
import { bolder, center, Codes, Headers, right, translateHeaders } from '@szegedsw/table';
import { qsosOnInit } from 'src/app/common/qsos.common';
import { api } from 'src/app/config/config';
import { Qso } from 'src/app/entities/qso.entity';
import { translate } from '../../services/translate';
import { TranslatePipe } from '../../services/translate.pipe';

@Component({
   selector: 'app-reverse-qsos',
   templateUrl: './reverse-qsos.component.html',
   styleUrls: ['./reverse-qsos.component.css'],
})
export class ReverseQsosComponent implements OnInit {
   rows: Qso[] = [];

   headers: Headers;
   codes: Codes;

   details = [];
   detailHeaders: Headers;
   detailCodes: Codes;

   service: HttpService;
   params: Map<string, string> = new Map();
   queryParams: Params = {};

   constructor(private http: HttpClient, public route: ActivatedRoute, public router: Router) {
      this.service = new HttpService(this.http, api('nest', 'reverse-log'));
      this.headers = new Headers([
         'Date',
         'Time',
         right('Frequency'),
         center('Mode'),
         'Callsign',
         right('RST'),
         right('Sent'),
         right('RST'),
         right('Rcvd'),
         center('Status'),
         'Reason',
         center('Dupe'),
         right('Point'),
         center('Mult'),
      ]);
      this.codes = new Codes([
         'dateTimeDate',
         bolder('dateTimeTime'),
         right('freq'),
         center('mode'),
         bolder('callsign'),
         right('sRst'),
         right('sExch'),
         right('rRst'),
         right('rExch'),
         center('status'),
         'statusRsn',
         center('dupe'),
         right('qsoPoints'),
         center('multiplier'),
      ]);
      this.detailHeaders = new Headers(['Callsign', 'Round', right('QSO'), right('Point'), right('Mult'), right('Score')]);
      this.detailCodes = new Codes([
         bolder('callsign'),
         bolder('name'),
         right('qsos'),
         right('points'),
         right('multipliers'),
         right('score'),
      ]);
      const translateComponent = () => {
         translateHeaders(this.headers, translate);
         translateHeaders(this.detailHeaders, translate);
      };
      translateComponent();
      TranslatePipe.changed.subscribe(translateComponent);
   }

   ngOnInit() {
      qsosOnInit(this);
   }
}
