import { ParamMap } from '@angular/router';
import { Locales } from './locales';
import { TranslatePipe } from './translate.pipe';

export class TranslateHandler {
   private static localeParam = false;

   static construct(allowedLocaleKeys: string[]) {
      this.setLocale(this.getLocale() || TranslatePipe.getLocaleKey(TranslatePipe.getDefaultLocale()));
      TranslatePipe.setAllowedLocaleKeys(allowedLocaleKeys);
      TranslatePipe.setLocale(Locales[this.getLocale()] || TranslatePipe.getDefaultLocale());
   }

   static queryParamMap(queryParamMap: ParamMap) {
      this.localeParam = false;
      if (Object.keys(Locales).includes(queryParamMap.get('locale')!)) {
         this.localeParam = true;
         this.setLocale(queryParamMap.get('locale')!);
         TranslatePipe.setLocale(Locales[this.getLocale()]);
      }
   }

   static async startSession(AppComponent: any, thisComponent: any, loginPage: boolean, callback: any) {
      AppComponent.session.acceptLanguages.split(',').some((accept: string) => {
         const locale = accept.split(';')[0].split('-')[0];
         if (Object.keys(Locales).includes(locale) && !this.localeParam) {
            if (this.getLocale() !== locale && !this.getLockedLocale()) {
               TranslatePipe.setLocale(Locales[locale]);
            }
            return true;
         }
         return false;
      });

      const process = async () => {
         const localeKey = TranslatePipe.getLocaleKey(TranslatePipe.getLocale());
         if (this.getLocale() !== localeKey) {
            this.setLocale(localeKey);
            await thisComponent.redirect.reloadPage(callback);
         } else {
            callback();
         }
      };

      if (TranslatePipe.getLocale() !== TranslatePipe.getDefaultLocale()) {
         TranslatePipe.getService(thisComponent.translateService, loginPage, process);
      } else {
         TranslatePipe.setTranslations({});
         await process();
      }
   }

   static setLockedLocale() {
      localStorage.setItem('locked-locale', String(true));
   }

   static getLockedLocale() {
      return Boolean(localStorage.getItem('locked-locale'));
   }

   static getLocale() {
      return localStorage.getItem('locale');
   }

   static setLocale(locale: string) {
      if (!locale) {
         localStorage.removeItem('locale');
      } else if (TranslatePipe.isAllowedLocale(Locales[locale])) {
         localStorage.setItem('locale', locale);
      }
   }
}
