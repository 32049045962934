import { HttpResponse } from '@angular/common/http';
import { Pipe } from '@angular/core';
import { isResultValid } from '@szegedsw/http.service';
import { Subject } from 'rxjs';
import { translate } from './translate';
import { Locales } from './locales';

@Pipe({
   name: 'translate',
   pure: false,
})
export class TranslatePipe {
   private static translations: { [key: string]: string } = {};
   private static locale: string = Locales.en;
   private static defaultLocale: string = Locales.en;
   private static allowedLocaleKeys: string[] = Object.keys(Locales);

   private static changedSubject: Subject<void> = new Subject();
   public static changed = TranslatePipe.changedSubject.asObservable();

   static getService(service: any, loginPage: boolean, callback: any) {
      const defaultLocaleKey = this.getLocaleKey(TranslatePipe.defaultLocale);
      const localeKey = this.getLocaleKey(TranslatePipe.locale);
      service.get({ defaultLocale: defaultLocaleKey, locale: localeKey, loginPage }, (res: HttpResponse<any>) => {
         if (isResultValid(res)) {
            TranslatePipe.setTranslations(
               (res.body || []).reduce((previous: any, current: any) => {
                  previous[current[defaultLocaleKey]] = current[localeKey];
                  return previous;
               }, {}) || {},
            );
            callback(res.body);
         }
      });
   }

   static getTranslations() {
      return this.translations;
   }

   static getLocale() {
      return this.locale;
   }

   static getDefaultLocale() {
      return this.defaultLocale;
   }

   static getAllowedLocaleKeys() {
      return this.allowedLocaleKeys;
   }

   static setTranslation(key: string, value: string) {
      this.translations[key] = value;
      this.changedSubject.next();
      return this;
   }

   static setTranslations(translations: { [key: string]: string }) {
      this.translations = { ...translations };
      this.changedSubject.next();
      return this;
   }

   static setLocale(locale: string) {
      if (this.isAllowedLocale(locale)) {
         this.locale = locale;
      }
      return this;
   }

   static setDefaultLocale(locale: string) {
      if (this.isAllowedLocale(locale)) {
         this.defaultLocale = locale;
      }
      return this;
   }

   static setAllowedLocaleKeys(keys: string[]) {
      this.allowedLocaleKeys = keys;
      return this;
   }

   static getLocaleKey(locale: string) {
      return Object.keys(Locales).find((key) => Locales[key] === locale) || Object.keys(Locales)[0];
   }

   static current() {
      return this.getLocaleKey(this.getLocale());
   }

   static default() {
      return this.getLocaleKey(this.getDefaultLocale());
   }

   static isAllowedLocale(locale: string) {
      return this.allowedLocaleKeys.includes(this.getLocaleKey(locale));
   }

   transform(value: string) {
      return translate(value);
   }
}
