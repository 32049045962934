import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { center, Codes, convertDateToLocaleDate, Headers, IPageChanged, Pagination, translateHeaders } from '@szegedsw/table';
import { api } from '../config/config';
import { CallBook, CallBooks } from '../entities/callbook.entity';
import { translate } from '../services/translate';
import { TranslatePipe } from '../services/translate.pipe';

@Component({
   selector: 'app-callbook',
   templateUrl: './callbook.component.html',
   styleUrls: ['./callbook.component.css'],
})
export class CallbookComponent implements OnInit {
   headers = new Headers([
      'Callsign',
      'Name',
      'Country',
      'City',
      'Street',
      'Validity',
      'Level',
      center('Morse'),
      'Chief',
      'State',
   ]);
   codes = new Codes([
      CallBooks.callSign,
      CallBooks.nameOfLicensee,
      CallBooks.country,
      CallBooks.city,
      CallBooks.streetHouse,
      CallBooks.validity,
      CallBooks.levelOfExam,
      center(CallBooks.morse),
      CallBooks.chiefOperator,
      CallBooks.stateOfLicence,
   ]);
   rows: CallBook[] = [];

   private callbookService: HttpService;
   pagination: Pagination;

   searchForm = this.fb.group({
      search: this.fb.control('', Validators.required),
   });

   constructor(private http: HttpClient, private route: ActivatedRoute, private fb: FormBuilder) {
      this.pagination = new Pagination(0, translate, this.route);
      this.pagination.itemsPerPage = 20;
      this.callbookService = new HttpService(this.http, api('nest', 'callbook'));
      const translateComponent = () => translateHeaders(this.headers, translate);
      translateComponent();
      TranslatePipe.changed.subscribe(translateComponent);
   }

   ngOnInit() {
      this.getCallbook();
   }

   getCallbook(search?: string) {
      this.callbookService.get<{ callbooks: CallBook[]; count: number }>({ search, ...this.pagination.aggr() }, (res) => {
         if (isResultValid(res)) {
            this.rows = res.body.callbooks;
            convertDateToLocaleDate(this.rows, [CallBooks.validity], TranslatePipe.getLocale());
            this.pagination.total(res.body.count);
         } else {
            this.rows = [];
            this.pagination.total(0);
         }
      });
   }

   onPageChanged(event: IPageChanged) {
      this.pagination.page(event, () => this.getCallbook(this.searchForm.getRawValue().search));
   }

   onSearch() {
      this.pagination.set(1, () => this.getCallbook(this.searchForm.getRawValue().search));
   }
}
