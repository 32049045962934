import { NgModule } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, huLocale, fiLocale } from 'ngx-bootstrap/locale';

export const Locales = {
   en: 'en-GB',
   hu: 'hu-HU',
   fi: 'fi-FI',
};

export function defineLocales(): void {
   defineLocale(Locales.en.toLocaleLowerCase(), enGbLocale);
   defineLocale(Locales.hu.toLocaleLowerCase(), huLocale);
   defineLocale(Locales.fi.toLocaleLowerCase(), fiLocale);
}

@NgModule({
   declarations: [],
   imports: [],
   exports: [],
})
export class LocaleModule {
   constructor() {
      defineLocales();
   }
}
