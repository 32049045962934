import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { addButton, center, Codes, Headers, translateHeaders } from '@szegedsw/table';
import * as _ from 'lodash';
import { AppComponent } from '../app.component';
import { Event, Events } from '../entities/event.entity';
import { translate } from '../services/translate';
import { TranslatePipe } from '../services/translate.pipe';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
   events: Event[] = [];

   year = new Date().getFullYear();

   eventYears: [number, Event[]][] = [];

   allEvents: [number, Event[]][] = [];

   mode: { tile: boolean; list: boolean; all: boolean } = { tile: false, list: false, all: false };

   codes: Codes = new Codes(['name', center('finalResults'), center('claimedResults'), center('soapbox'), center('awardButton')]);

   headers: Headers = new Headers([
      'Event',
      center('Final results'),
      center('Claimed results'),
      center('Soapbox'),
      center('Award'),
   ]);

   headersThisYear: Headers = new Headers([
      'Event',
      center('Log submission'),
      center('Online logger'),
      center('Final results'),
      center('Claimed results'),
      center('Soapbox'),
      center('Award'),
   ]);

   codesThisYear: Codes = new Codes([
      'name',
      center('submit'),
      center('onlineLogger'),
      center('finalResults'),
      center('claimedResults'),
      center('soapbox'),
      center('awardButton'),
   ]);

   constructor(private router: Router) {}

   async ngOnInit() {
      this.events = await AppComponent.getEvents();
      this.eventYears = _.keys(_.groupBy(this.events, 'year'))
         .map((k) => Number(k))
         .filter((k) => k !== this.year)
         .reverse()
         .map((y) => [y, this.events.filter((e) => e[Events.year] === y && e[Events.isFinal])]);
      translateHeaders(this.headers, translate);
      addButton(this.events, 'submit', translate('Log submission'), ['btn-primary'], 'isSubmit');
      addButton(this.events, 'onlineLogger', translate('Online logger'), ['btn-primary'], 'isSubmit');
      addButton(this.events, 'finalResults', translate('Preliminary results'), ['btn-primary'], 'isPreliminary');
      addButton(this.events, 'finalResults', translate('Final results'), ['btn-primary'], 'isFinal');
      addButton(this.events, 'claimedResults', translate('Claimed results'), ['btn-primary'], 'isClaimed');
      addButton(this.events, 'soapbox', translate('Soapbox'), ['btn-primary'], 'isSoapbox');
      this.events.forEach((event) => {
         if (event.award || event.awardSite) {
            addButton([event], 'awardButton', translate('Award'), ['btn-primary']);
         }
      });
      TranslatePipe.changed.subscribe(() => {
         translateHeaders(this.headers, translate);
         addButton(this.events, 'submit', translate('Log submission'), ['btn-primary'], 'isSubmit');
         addButton(this.events, 'onlineLogger', translate('Online logger'), ['btn-primary'], 'isSubmit');
         addButton(this.events, 'finalResults', translate('Preliminary results'), ['btn-primary'], 'isPreliminary');
         addButton(this.events, 'finalResults', translate('Final results'), ['btn-primary'], 'isFinal');
         addButton(this.events, 'claimedResults', translate('Claimed results'), ['btn-primary'], 'isClaimed');
         addButton(this.events, 'soapbox', translate('Soapbox'), ['btn-primary'], 'isSoapbox');
         this.events.forEach((event) => {
            if (event.award || event.awardSite) {
               addButton([event], 'awardButton', translate('Award'), ['btn-primary']);
            }
         });
      });
      this.allEvents = _.keys(_.groupBy(this.events, 'year'))
         .map((k) => Number(k))
         .reverse()
         .map((y) => [y, this.events.filter((e) => e[Events.year] === y)]);
      translateHeaders(this.headers, translate);
      addButton(this.events, 'submit', translate('Log submission'), ['btn-primary'], 'isSubmit');
      addButton(this.events, 'onlineLogger', translate('Online logger'), ['btn-primary'], 'isSubmit');
      addButton(this.events, 'finalResults', translate('Preliminary results'), ['btn-primary'], 'isPreliminary');
      addButton(this.events, 'finalResults', translate('Final results'), ['btn-primary'], 'isFinal');
      addButton(this.events, 'claimedResults', translate('Claimed results'), ['btn-primary'], 'isClaimed');
      addButton(this.events, 'soapbox', translate('Soapbox'), ['btn-primary'], 'isSoapbox');
      this.events.forEach((event) => {
         if (event.award || event.awardSite) {
            addButton([event], 'awardButton', translate('Award'), ['btn-primary']);
         }
      });
      TranslatePipe.changed.subscribe(() => {
         translateHeaders(this.headers, translate);
         addButton(this.events, 'submit', translate('Log submission'), ['btn-primary'], 'isSubmit');
         addButton(this.events, 'onlineLogger', translate('Online logger'), ['btn-primary'], 'isSubmit');
         addButton(this.events, 'finalResults', translate('Preliminary results'), ['btn-primary'], 'isPreliminary');
         addButton(this.events, 'finalResults', translate('Final results'), ['btn-primary'], 'isFinal');
         addButton(this.events, 'claimedResults', translate('Claimed results'), ['btn-primary'], 'isClaimed');
         addButton(this.events, 'soapbox', translate('Soapbox'), ['btn-primary'], 'isSoapbox');
         this.events.forEach((event) => {
            if (event.award || event.awardSite) {
               addButton([event], 'awardButton', translate('Award'), ['btn-primary']);
            }
         });
      });
      if (localStorage.getItem('mode')) {
         this.mode[localStorage.getItem('mode')] = true;
      } else {
         this.changeMode('tile');
      }
   }

   onButton(event: { row: Event; id: string }) {
      if (event.id['str'] === 'finalResults' || event.id['str'] === 'submit' || event.id['str'] === 'preliminaryResults') {
         const link = event.row.isSubmit
            ? ['submit', event.row._id]
            : event.row.isFinal
            ? ['results', event.row._id, 'final']
            : ['results', event.row._id, 'preliminary'];
         this.router.navigate(link);
      } else if (event.id['str'] === 'claimedResults') {
         const link = ['results', event.row._id, 'claimed'];
         this.router.navigate(link);
      } else if (event.id['str'] === 'onlineLogger') {
         const link = ['logger', event.row._id];
         this.router.navigate(link);
      } else if (event.id['str'] === 'submittedList') {
         const link = ['stats', event.row._id, 'submitted-list'];
         this.router.navigate(link);
      } else if (event.id['str'] === 'soapbox') {
         const link = ['results', event.row._id, 'soapbox'];
         this.router.navigate(link);
      } else if (event.id['str'] === 'awardButton') {
         if (event.row.award) {
            this.router.navigateByUrl('/award?event=' + event.row._id);
         } else if (event.row.awardSite) {
            window.open(event.row.awardSite, '_blank');
         }
      }
   }

   navigateToAward(event: Event) {
      if (event.award) {
         this.router.navigateByUrl('/award?event=' + event._id);
      } else if (event.awardSite) {
         window.open(event.awardSite, '_blank');
      }
   }

   changeMode(type: 'tile' | 'list' | 'all') {
      this.mode.all = false;
      this.mode.list = false;
      this.mode.tile = false;
      this.mode[type] = true;
      localStorage.setItem('mode', type);
   }
}
