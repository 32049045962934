export const chosen = 'chosen';

export enum Routes {
   nest = '/nest',
}

export enum Controllers {
   session = 'session',
   'category-types' = 'category-types',
   categories = 'categories',
   rounds = 'rounds',
   events = 'events',
   contests = 'contests',
   claimed = 'claimed',
   preliminary = 'preliminary',
   'reverse-logs' = 'reverse-logs',
   uploads = 'uploads',
   emailCsv = 'uploads/emails-csv',
   fileDownload = 'files/download',
   fileDelete = 'files/delete',
   filePublicDownload = 'files/public-download',
   sentExchangeCsv = 'log/sent-exchange-csv',
   users = 'users',
   callbook = 'callbook',
   'uploads/files-zip' = 'uploads/files-zip',
   'events/admin' = 'events/admin',
   'events/upload-assets' = 'events/upload-assets',
   'events/upload-multiple-assets' = 'events/upload-multiple-assets',
   'events/award' = 'events/award',
   'events/rounds' = 'events/rounds',
   'events/status' = 'events/status',
   'events/eval-categories' = 'events/eval-categories',
   'log/award' = 'log/award',
   'log/eval-categories' = 'log/eval-categories',
   'users/events' = 'users/events',
   'users/list' = 'users/list',
   'users/rights' = 'users/rights',
   'users/contests' = 'users/contests',
   'users/category-types' = 'users/category-types',
   'users/events/possible' = 'users/events/possible',
   'users/reset-password' = 'users/reset-password',
   'rounds/possible-for-event' = 'rounds/possible-for-event',
   'eval-category/possible' = 'eval-category/possible',
   'eval-category/admin' = 'eval-category/admin',
   'eval-category' = 'eval-category',
   'rounds/possible-for-eval-category' = 'rounds/possible-for-eval-category',
   'eval-category/categories' = 'eval-category/categories',
   'eval-category/rounds' = 'eval-category/rounds',
   'events/list' = 'events/list',
   'log/dummy-award' = 'log/dummy-award',
   'contests/sysadmin' = 'contests/sysadmin',
   'category-types/sysadmin' = 'category-types/sysadmin',
   'log/soapbox' = 'log/soapbox',
   'competitor' = 'competitor',
   'competitor/member' = 'competitor/member',
   'events/options' = 'events/options',
   'events/image' = 'events/image',
   'events/clone' = 'events/clone',
   'events/active' = 'events/active',
   'eval-category/event' = 'eval-category/event',
   'events/email-options' = 'events/email-options',
   'events/email-html' = 'events/email-html',
   'statistics/logs-count' = 'statistics/logs-count',
   'log/operators' = 'log/operators',
   'translation' = 'translation',
   'translation/admin' = 'translation/admin',
   'uploads/station-sponsors' = 'uploads/station-sponsors',
   'statistics/callsign-occurence' = 'statistics/callsign-occurence',
   'dxcc/readable' = 'dxcc/readable',
   'submit/preserve-categories' = 'submit/preserve-categories',
   'submit/event-details' = 'submit/event-details',
   'submit/upload' = 'submit/upload',
   'submit/claim' = 'submit/claim',
   'log/trash' = 'log/trash',
   'log/matched' = 'log/matched',
   'submit/review-qsos' = 'submit/review-qsos',
   'log' = 'log',
   'qso' = 'qso',
   'reverse-log' = 'reverse-log',
   'submit/start-online-logger' = 'submit/start-online-logger',
   'qso/online-logger' = 'qso/online-logger',
   'events/online-logger' = 'events/online-logger',
   'submit/claim-online-logger' = 'submit/claim-online-logger',
   'dxcc' = 'dxcc',
   'dxcc/update' = 'dxcc/update',
   'results/winners-csv' = 'results/winners-csv',
   'submit/online-logger-details' = 'submit/online-logger-details',
   'statistics/sum-of-total-scores-per-region' = 'statistics/sum-of-total-scores-per-region',
   'statistics/sum-of-total-scores-per-country' = 'statistics/sum-of-total-scores-per-country',
   'statistics/sum-of-total-scores-per-country-per-round' = 'statistics/sum-of-total-scores-per-country-per-round',
   'statistics/submitted-list' = 'statistics/submitted-list',
   'claimed/reset-statuses' = 'claimed/reset-statuses',
   'submit/reclaim-upload' = 'submit/reclaim-upload',
   'submit/reclaim-event' = 'submit/reclaim-event',
   'preliminary/so3band' = 'preliminary/so3band',
   'preliminary/single-band' = 'preliminary/single-band',
   'preliminary/ten-minute-rule' = 'preliminary/ten-minute-rule',
   'preliminary/operating-6-hours' = 'preliminary/operating-6-hours',
   'uploads/only-bad-submissions' = 'uploads/only-bad-submissions',
}

export function api(type: keyof typeof Routes, controller: keyof typeof Controllers) {
   return Routes[type] + '/' + Controllers[controller];
}

export const allowedLocales = ['en', 'hu'];

export const callsignRegExp = '^(([A-Z,0-9]+[/])(?=([A-Z]*[0-9]+)+[A-Z]+))?([A-Z]*[0-9]+)+[A-Z,0-9]+(([/][A-Z,0-9]+))?$';

export const timeFormat: Intl.DateTimeFormatOptions = { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour12: false };
export const dateFormat: Intl.DateTimeFormatOptions = { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit' };
