import { bolder, center, Codes, Headers, right, translateHeaders } from '@szegedsw/table';
import { PreliminaryQsosComponent as EvalPreliminaryQsos } from '../eval/preliminary-qsos/preliminary-qsos.component';
import { PreliminaryQsosComponent as ResultsPreliminaryQsos } from '../results/preliminary-qsos/preliminary-qsos.component';
import { translate } from '../services/translate';
import { TranslatePipe } from '../services/translate.pipe';
import { qsosOnInit } from './qsos.common';

export function preliminaryQsosConstructor(preliminaryQsos: EvalPreliminaryQsos | ResultsPreliminaryQsos) {
   preliminaryQsos.headers = new Headers([
      '#',
      'Date',
      'Time',
      right('Frequency'),
      center('Mode'),
      'Callsign',
      right('RST'),
      right('Sent'),
      right('RST'),
      right('Rcvd'),
      center('Status'),
      'Reason',
      center('Comment'),
      center('Dupe'),
      right('Point'),
      center('Mult'),
   ]);
   preliminaryQsos.codes = new Codes([
      'index',
      'dateTimeDate',
      bolder('dateTimeTime'),
      right('freq'),
      center('mode'),
      bolder('callsign'),
      right('sRst'),
      right('sExch'),
      right('rRst'),
      right('rExch'),
      center('status'),
      'statusRsn',
      center('comment'),
      center('dupe'),
      right('points'),
      center('multis'),
   ]);
   preliminaryQsos.detailHeaders = new Headers([
      'Callsign',
      'Operators',
      'Round',
      right('QSO'),
      right('Point'),
      right('Mult'),
      right('Score'),
   ]);
   preliminaryQsos.detailCodes = new Codes([
      bolder('callsign'),
      bolder('upload.operators'),
      bolder('name'),
      right('qsos'),
      right('points'),
      right('multipliers'),
      right('score'),
   ]);
   translatePreliminaryQsos(preliminaryQsos);
   TranslatePipe.changed.subscribe(() => translatePreliminaryQsos(preliminaryQsos));
}

function translatePreliminaryQsos(preliminaryQsos: EvalPreliminaryQsos | ResultsPreliminaryQsos) {
   translateHeaders(preliminaryQsos.headers, translate);
   translateHeaders(preliminaryQsos.detailHeaders, translate);
}

export function preliminaryQsosOnInit(preliminaryQsos: EvalPreliminaryQsos | ResultsPreliminaryQsos) {
   qsosOnInit(preliminaryQsos);
}
