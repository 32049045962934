import { TranslatePipe } from './translate.pipe';

/**
 * Translates a given key based on the current locale and translations.
 *
 * @param key - The key to translate.
 * @returns The translated string or the original key if a translation is not available.
 */
export function translate(key: string): string | undefined {
   if (!key) return undefined;

   const endWithColon = key.endsWith(':');
   if (endWithColon) {
      key = key.slice(0, -1);
   }

   const translations = TranslatePipe.getTranslations();
   let translation = '';

   if (TranslatePipe.getLocale() === TranslatePipe.getDefaultLocale()) {
      translation = key;
   } else if (!translations || !Object.keys(translations).includes(key) || (translations[key] || []).length === 0) {
      translation = key;
   } else {
      translation = translations[key];
   }

   return translation ? translation + (endWithColon ? ':' : '') : undefined;
}
